import { useEffect } from 'react';
import gpsLogImage from './images/GPSLog.png'
import moment from 'moment';
import 'moment-duration-format'
import 'moment-timezone'
import { useState } from 'react';
import { getTotalDistance, getAverageSpeed, getNumberOfStops, calculateSpeedWithStops } from '../core/GpsLogTracker';
import { ResponsiveContainer, Line, ComposedChart, XAxis, YAxis, Bar, Tooltip, Legend } from 'recharts';

function GpsLogStats ({data}) {

    const launchTime = moment.tz("2023-07-20 08:31:00", "America/Los_Angeles")
    // const endTime = moment.tz("2023-07-08 11:20:00", "America/Los_Angeles")

    const [timeSinceLaunch, setTimeSinceLaunch] = useState(moment.duration(moment().diff(launchTime)))
    const [totalDistance, setTotalDistance] = useState()
    const [averageSpeed, setAverageSpeed] = useState()
    const [stops, setStops] = useState(0)
    const [movementData, setMovementData] = useState([{}])

    // We are unable to use altitude data because it is not valid
    //const [altitudeData, setAltitudeData] = useState([])

    // Recalculate Stats on Data Change
    useEffect(() => {
        // Recalculate Time Since Launch
        // NO LONGER NEEDED! EXPERIMENT INACTIVE
        const intervalId = setInterval(() => {
            setTimeSinceLaunch(moment.duration(moment().diff(launchTime)))
        }, 1000)

        if (data !== undefined && data.length > 0) {
            // Recalculate Total Distance
            setTotalDistance(getTotalDistance(data).toFixed(2))

            // Recalculate Number of Stops
            setStops(getNumberOfStops(data))

            // Calculate Speed and Stops data for movement graph
            setMovementData(calculateSpeedWithStops(data))

            // We are unable to use altitude data because it is not valid
            //
            // Recalculate Altitude Data
            // let newAltitudeData = []
            // for (var i = 0; i < data.length; i++) {
            //     newAltitudeData.push({time: moment(data[i].dateTime).format('h:MMA'), altitude: data[i].altitude})
            // }
            // setAltitudeData(newAltitudeData)
        }
        
        // Return the cleanup function
        // NO LONGER NEEDED! EXPERIMENT INACTIVE
        return () => clearInterval(intervalId)
    },[data]) 

    // Recalculate Average Speed 
    useEffect(() => {
        setAverageSpeed(getAverageSpeed(totalDistance, timeSinceLaunch.asHours()).toFixed(2))
    }, [totalDistance])

    return (
        <div>

            <div className="GpsLog-Row">
                <div className="GpsLogStatContainer">
                    <div className="GpsLogStat">
                        1.53 mi
                        {/* {totalDistance ? `${totalDistance} mi` : 'N/A'} */}
                    </div>
                    <div className="GpsLogStatLabel">
                        Distance
                    </div>
                </div>
                <div className="GpsLogStatContainer">
                    <div className="GpsLogStat">
                        18 days, 1 hour
                        {/* {timeSinceLaunch.asSeconds() > 0 ? timeSinceLaunch.format() : `${(timeSinceLaunch).format('hh:mm:ss').slice(1)}`} */}
                    </div>
                    <div className="GpsLogStatLabel">
                        Time Traveled
                        {/* {timeSinceLaunch.asSeconds() > 0 ? 'Travel Time' : 'Time Until Launch'} */}
                    </div>
                </div>
            </div>

            <div className="GpsLog-Row">
                <div className="GpsLogStatContainer">
                    <div className="GpsLogStat">
                        {isNaN(averageSpeed) ? 0 : averageSpeed} mph
                    </div>
                    <div className="GpsLogStatLabel">
                        Average Speed
                    </div>
                </div>
                <div className="GpsLogStatContainer">
                    <div className="GpsLogStat">
                        3 snags
                        {/* {stops} snags */}
                    </div>
                    <div className="GpsLogStatLabel">
                        Extended Stops
                    </div>
                </div>
            </div>
            
            {/* We are unable to use altitude data because it is not valid */}
            {/* <div className="GpsLog-Row">
                <div className="GpsLogStatAltitudeGraphContainer">
                    <div className="GpsLogStatAltitudeGraphLabel">
                        Altitude
                    </div>
                    <div className="GpsLogStatAltitudeGraph">
                        <ResponsiveContainer width='100%' height={300}>
                            <LineChart data={altitudeData}>
                                <Line type="monotone" dataKey="altitude" stroke="#8884d8"/>
                                <CartesianGrid stroke="#ccc" />
                                <XAxis dataKey="time" />
                                <YAxis />
                            </LineChart>
                        </ResponsiveContainer>
                    </div>
                </div>
            </div>*/}

            <div className="GpsLog-Row">
                <div className="GpsLogStatGraphContainer">
                    <div className="GpsLogStatGraphLabel">
                        Movement
                    </div>
                    <div className="GpsLogStatGraph">
                        <ResponsiveContainer width='100%' height={300}>
                            <ComposedChart data={movementData}>
                                <Line 
                                    type="monotone"
                                    dataKey="speed"
                                    stroke="#28704A"
                                    legendType='Speed Since Last Ping'
                                />
                                <Line
                                    type="monotone"
                                    dataKey="currentAverageSpeed"
                                    stroke="#F6A800"
                                    legendType='Average Speed'
                                />
                                <Bar 
                                    dataKey="stop"
                                    barSize={20}
                                    
                                    fill="#D93232"
                                    legendType='Stops'
                                />
                                <XAxis
                                    dataKey="time"
                                    tickFormatter={(tickItem) => moment(tickItem).format('M/D h:mmA')}
                                    tickSize={12}
                                    minTickGap={12}
                                    scale='time'
                                    type='number'
                                    domain={['dataMin', 'dataMax']}
                                    padding='no-gap'
                                />
                                <YAxis
                                    label={{ value: 'mph', angle: -90, position: 'insideLeft' }}/>
                                <Tooltip 
                                    labelFormatter={(label) => moment(label).format('M/D h:mmA')}
                                />
                                <Legend />
                            </ComposedChart>
                        </ResponsiveContainer>
                    </div>
                </div>
            </div>

            <div className="GpsLog-Row"> 
                <div style={{width: "100%", height: "250px"}}>
                    <img style={{width: "100%", height: "100%", objectFit: "contain"}} src={gpsLogImage} alt="The GPS Log"/>
                </div>
            </div>
        </div>
    )
}

export default GpsLogStats;
