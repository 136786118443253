import McKenzieRiver from './images/McKenzieRiver.jpg'

function GpsLogSummary () 
{

    return (
        <div>
            <h1>Tracker View</h1>
            <span className="HeaderTextLabel">Collaborators: <i><a href="https://ianeschervierck.com/">Ian Escher Vierck</a>, <a href="https://kadencen.com/">Kadence Neuens</a>, and <a href="https://wnb4.com/">William Bonner</a></i></span>
            <p>
                The idea of strapping a GPS to a piece of wood is not a new one. Researchers, like the ones at <a href="https://andrewsforest.oregonstate.edu/">HJ Andrews</a>, have been doing similar projects for years. Inspired by the idea of documenting the log's journey, and imagining the voyage large wood takes from the <a href="https://www.fs.usda.gov/research/treesearch/3073">mountains to the sea</a>, Will Bonner and I had the idea of tracking wood while it travels down the McKenzie River.
            </p>
            <p>
                GPS Log tracks live data of its movements. GPS log was engineered like a boat to consistently float in the same orientation with its antenna pointing towards the sky. As the log pings every two minutes, the data is displayed live on GPSLogDrive.com for folks to watch as it makes its way downriver.
            </p>
            <p>
                My collaborator, Kadence Neuens, helped engineer this website to track GPS Log and share information about its voyage thus far. Will Bonner will take the data, which includes altitude and coordinates, and make data visualization art, for further interpretation
            </p>
            <div style={{width: "100%", height: "300px"}}>
                <img style={{width: "100%", height: "100%", objectFit: "cover"}} src={McKenzieRiver} alt="The McKenzie River"/>
            </div>
        </div>
    )
}

export default GpsLogSummary
