import gpsLogNoAntennaImage from './images/GPSLogNoAntenna.png'

function GpsLogNavBar (props) {

    return (
        <div className="GpsLogNavBar">
            <div className="NavTitleLine">
                <div style={{display: "inline-block" ,width: "12em", paddingRight: "1em"}}>
                    <img style={{width: "100%", height: "100%", objectFit: "contain"}} src={gpsLogNoAntennaImage} alt="The GPS Log"/>
                </div>
                <a href="./"><h1 className="NavTitle">GPS LOG</h1></a>
            </div>
            <ul>
                <li><a href="./">Conclusion</a></li>
                <li><a href="./experiment">Tracker View</a></li>
                <li><a href="./forest-to-the-sea">Forest to the Sea</a></li>
            </ul>
            
        </div>
    )
}

export default GpsLogNavBar