import { useEffect, useState } from "react";
import './colors.css'
import './styles.css'
import gpsLogMapStyles from "./GpsLogMapStyles";
import gpsLogMarker from "./images/marker.svg";
import { addMarker, drawPolyline, recenterMap } from "../core/GpsLogMapUtil.jsx";
import moment from 'moment';

function GpsLogMap ({data}) {

    const [markers, setMarkers] = useState([])
    const [map, setMap] = useState(null)
    const [google, setGoogle] = useState(window.google)

    useEffect(() => {
        if (!map) {
            // Grab google object from dynamic API loader
            initMap()
        }
    }, [])

    useEffect(() => {
        if (map && data.length > 0) {
            let newPoints = [];
            
            for (var i = 0; i < data.length; i+=3) {
                let point = { latitude: data[i].latitude, longitude: data[i].longitude }
                let timeStamp = moment(data[i].dateTime).format('DD/MM/YYYY h:mm:ss a')

                // Only add a marker icon for the first item in data
                //if (i === 0) var marker = addMarker(google, map, point, gpsLogMarker, timeStamp)
                var marker = addMarker(google, map, point, '', timeStamp)

                newPoints.push(marker.position)
            }
        // Add the original drop off point
        const McKenzieRiverDropoff = { lat: 44.11975277777778, lng: -122.63231666666665 }
        newPoints.push(McKenzieRiverDropoff)
        setMarkers(newPoints)
        //recenterMap(map, data[0])
        }
    }, [data, map])

    useEffect(() => {
        if (google && map && markers) {
            drawPolyline(google, map, markers);
        }
    }, [markers])

    async function initMap() {

        // Dropoff Points
        const McKenzieRiverDropoff = { lat: 44.11975277777778, lng: -122.63231666666665 }
        const Experiment2Dropoff = { lat: 44.11094, lng: -122.65775 }
        const ExperimentConclusionCenterPoint = { lat: 44.113917, lng: -122.646211 }

        //const startingPosition = data.length > 0 ? data[0] : McKenzieRiverDropoff
        const startingPosition = ExperimentConclusionCenterPoint

        const { Map } = await google.maps.importLibrary("maps");
        
        let newMap = new Map(document.getElementById("map"), {
            disableDefaultUI: true, 
            center: startingPosition,
            zoom: 15,
        });
        newMap.setOptions({styles: gpsLogMapStyles})

        setMap(newMap)

        // // Add temporary stationary log marker
        // new google.maps.Marker({
        //     map,
        //     //position: startingPosition,
        //     title: "Stationary",
        //     icon: {
        //         url: gpsLogMarker,
        //         scaledSize: new google.maps.Size(80, 80)
        //     },
        //     animation: google.maps.Animation.DROP
        // });
    }

    // Write a function that fades the component out over 1 second and removes it from the DOM when it is
    // clicked on.
    function removeComponent(className) {
        let element = document.getElementsByClassName(className)[0]
        element.style.opacity = 0
        setTimeout(() => {
            element.remove()
        }, 1000)
    }


    return (
        <div className="GpsLogMapWrapper">
            <div className="GpsLogMap" id="map" style={{height: '100%', width: '100%'}}/>
            {/* <div className="GpsLogMapOverlay" onClick={() => removeComponent('GpsLogMapOverlay')}>
                <div className="GpsLogMapBanner">
                The GPS Log experiment has concluded!<br/><span>Read more below or click to view the map</span>
                </div>
            </div> */}
        </div>
    )
}
export default GpsLogMap
