import GpsLogConclusion from '../components/GpsLogConclusion';

function GpsLogConclusionPage (props) {

    return (
        <div className="ConclusionWrapper">
            <GpsLogConclusion/>
        </div>
    )
}

export default GpsLogConclusionPage;