import axios from 'axios';
import moment from 'moment';
import 'moment-duration-format'

export function getGpsLogData() {
    return axios.get('https://gps-log-tracker.azurewebsites.net/api/gps_log_data_service', { data: undefined })
        .then(response => {
            const data = response.data
            // Filter data for entries between dates
            const filteredData = data.filter(entry => {
                return (moment(entry.dateTime).isAfter(moment.tz("2023-07-08 11:20:00", "America/Los_Angeles")) );
                // && moment(entry.dateTime).isBefore(moment.tz("2023-07-08 11:20:00", "America/Los_Angeles"))); 
            });
            return filteredData;
        })
        .catch(error => {
            console.log(error);
        });
}

function getDistanceBetweenPoints(point1, point2) {
    function toRadian(x) {
      return x * Math.PI / 180;
    }
    
    // Set long/lat of both points
    var lon1 = point1.longitude;
    var lat1 = point1.latitude;
    var lon2 = point2.longitude;
    var lat2 = point2.latitude;
  
    var R = 3958.8; // Radius of the Earth in miles
  
    // Convert to radians
    var dLat = toRadian(lat2 - lat1)
    var dLon = toRadian(lon2 - lon1)
    
    // Haversine Formula
    var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(toRadian(lat1)) * Math.cos(toRadian(lat2)) *
      Math.sin(dLon / 2) * Math.sin(dLon / 2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var distance = R * c;
  
    // Return distance in miles
    return distance;
}

// Write a function that takes an input of data which is an array of points and loops through them using the above getDistanceBetweenPoints function to calculate the total distance traveled 
export function getTotalDistance(data) {
    var distance = 0;
    // for (var i = 0; i < data.length - 1; i++) {
    //     distance += getDistanceBetweenPoints(data[i], data[i + 1]);
    // }
    
    // Better estimation of the true voyage since it snagged immediately after launch
    distance = getDistanceBetweenPoints(data[0], data[data.length - 1]);
    return distance;
}

export function getAverageSpeed(distance, time) {
    var speed = distance / time;
    return speed;
}

export function getNumberOfStops(data) {
    var count = 0;
    for (var i = 0; i < data.length; i++) {
        if (data[i].messageType === 'STOP') {
            count++;
        }
    }
    return count;
}

// Write a function that takes input data containing latitude, longitude, and a dateTime property
// and return an array of objects that contain: 
// - the speed in miles per hour since the previous point
// - a flag that indicates whether the point had a messageType of 'STOP'
// - the time of the point
export function calculateSpeedWithStops(data) {
    var speedData = [];
    var totalDuration = 0;
    var totalDistance = 0;
    try {
        for (var i = data.length - 1; i > 0; i--) {
            if (i < data.length - 1)
            {
                var distance = getDistanceBetweenPoints(data[i], data[i - 1]);
                var duration = moment.duration(
                moment(data[i-1].dateTime).diff(
                    moment(data[i].dateTime)
                    )
                ).asHours();
                var speed = getAverageSpeed(distance, duration);

                totalDuration += duration;
                totalDistance += distance;

                var currentAverageSpeed = getAverageSpeed(totalDistance, totalDuration);

                var durationStopped = null;
                if (data[i].messageType === 'STOP') {
                    durationStopped = moment.duration(
                        moment(data[i-1].dateTime).diff(
                            moment(data[i].dateTime)
                            )
                        ).asMinutes();
                }
                // Time is returned as a UNIX timestamp
                speedData.push({time: moment(data[i].dateTime).valueOf(), speed: speed, currentAverageSpeed: currentAverageSpeed, stop: data[i].messageType === 'STOP' ? 'Stopped' : null, durationStpped: durationStopped});
            }
        }
        return speedData;
    } catch (error) {
        console.log(error);
    }
    
}