import GpsLogJourneyMap from './images/GpsLogVoyageMap.png'
import LogDiagram from './images/LogDiagram.png'
import HoldingTheLog from './images/HoldingTheLog.jpg'
import RiverFlowDiagram from './images/RiverFlowDiagram.png'
import TechDiagram from './images/TechDiagram.png'
import './conclusionImageStyles.css'

function GpsLogConclusion () 
{
    return (
        <div className="GpsLogConclusion">
            <div className="GpsLogConclusionText">
                <h1>The Experiment</h1>
                <p>
                    Deployed on July 8th, 2023 and declared Missing in Action on October 25th, 2023, GPS Log traveled 1.5 miles. This journey down the McKenzie River went beyond expectations. On its journey down the McKenzie River, GPS Log got snagged 3 times, and was only interfered with once for routine maintenance and inspection.
                </p>
                
                <img id="GpsLogJourneyMap" src={GpsLogJourneyMap} alt="GPS Log's Journey Map" />
                <img id="LogDiagram" src={LogDiagram} alt="Log Diagram" />
                <p>
                    Following a log downstream presents its challenges. There are three known methods of tracking wood movement: find and retrieve, RFQ chips, and GPS Tracking Devices. Find and retrieve involves marking wood with identifiable markers, and searching for it until you spot it, this is treacherous and time-consuming. RFQ chips are small and waterproof but require navigating the waters with a scanner to locate them. GPS Tracking Devices can remotely share the location of a log, yet require batteries that are either very large or require routine maintenance.
                </p>

                <p>
                    To add to complications, wood can change shape and size as it moves downstream. With rapids strong enough, parts of the log could shatter. GPS Log presented its own unique challenges, its body was carefully selected from the University of Oregon’s Facilities brush pile for its’ characteristic fishiness. The shape was ideal for GPS Log’s aesthetic, but compromised the effectiveness of the tec which needs to be above the water, pointing towards the sky. In order to keep the GPS pointing in the right direction, GPS Log needed a ballast to stay upright. Multiple controlled and field studies were done in order to engineer and train GPS Log for a successful voyage. A railroad spike was used as a ballast to keep GPS Log pointing the right direction. 
                </p>

                <h1>The Final Snag</h1>
                <p>
                    GPS Log got snagged on July 27th and remained until October 25th when it was declared Missing in Action. GPS Log resisted all changes in the flows and height of the McKenzie River. After searchers scoured both sides of the McKenzie River, a mile each way, GPS Log was considered lost to the river. With that said, GPS Log has two waterproof tags with return information if found.
                </p>
                <img id="RiverFlowDiagram" src={RiverFlowDiagram} alt="River Flow Diagram" />

                <img id="TechDiagram" src={TechDiagram} alt="Tech Diagram" />
                <p>
                    GPS Log helps us imagine the movement of wood in the world, right now, wood is being knocked loose and moving downstream, closer to the ocean. During regular seasonal weather, these spurts of movement are few and far between and happen for very short distances. Wood is most likely to move during snowmelt down the McKenzie River when <a href="https://waterdata.usgs.gov/monitoring-location/14163150/#parameterCode=00065&period=P7D&showMedian=false">water levels and flows</a> are the highest. Most wood will not make it very far until it is permanently snagged, buried, or pushed ashore. However, 100 year rain events can release this wood in a unstoppable tidal wave of flood waters. 
                </p>
                <p>
                    Wood is an essential component to any Ocean ecosystem, and with current land use and management, the introduction of wood to the marine ecosystem is much more small than it used to be. The timeline in which this happens helps emphasize why the removal of wood from the environment can have long-term <a href="https://andrewsforest.oregonstate.edu/sites/default/files/lter/pubs/pdf/pub1992.pdf">consequences</a>, called ecological legacies.
                </p>

                <p>
                    GPS Log got stuck just downstream from the deployment spot. In the spirit of scientific inquiry, the short distance traveled by GPS Log is seen as a success in confirming the null hypothesis. The first deployment of GPS Log has informed and inspired future projects. Stay tuned for GPS Log 2.0, with a new name and better tech!
                </p>

            </div>
        </div>
    )
}

export default GpsLogConclusion
