// Write a function that uses the google maps api to add a marker to the map at a specific latitude and longitude with a custom icon
export function addMarker(google, map, point, icon, timeStamp=null) {
    var marker = new google.maps.Marker({
        position: {lat: point.latitude, lng: point.longitude},
        map: map,
        icon: 
        {
            url: icon,
            scaledSize: new google.maps.Size(50, 50)
        },
        animation: google.maps.Animation.DROP
    });

    // Add a mouseover event to the marker that renders a infowindow with the timeStamp in the top left
    // corner in bold, and the latitude and longitude below it besides each other
    if (timeStamp) {
        var infowindow = new google.maps.InfoWindow({
            content: '<div style="font-weight: bold;">' + timeStamp + '</div>' +
            '<div>' + point.latitude + ', ' + point.longitude + '</div>'
        });
        marker.addListener('mouseover', function() {
            infowindow.open(map, marker);
        });
        marker.addListener('mouseout', function() {
            infowindow.close();
        });
    }
    return marker;

    
}

// Write a function that loops through all the markers on the map and draws a polyline between them in order
export function drawPolyline(google, map, points) {
    // Define a symbol using SVG path notation, with an opacity of 1.
    const lineSymbol = {
        path: "M 0,-1 0,1",
        strokeOpacity: 1,
        scale: 4,
        strokeColor: "#D93232",
    };
    var polyline = new google.maps.Polyline({
        path: points,
        strokeOpacity: 0,
        icons: [
            {
                icon: lineSymbol,
                offset: "0",
                repeat: "20px",
            },
        ]
    });
    polyline.setMap(map);
    return polyline;
}

// Write a function that recenters the map on a specific point
export function recenterMap(map, point) {
    map.setCenter({lat: point.latitude, lng: point.longitude});
    map.setZoom(16)
}