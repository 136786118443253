import GpsLogRiverSection from "../components/GpsLogRiverSection";

function GpsLogForestToSeaPage (props) {

    return (
        <div className="RiverWrapper">
            <GpsLogRiverSection/>
        </div>
    )
}

export default GpsLogForestToSeaPage;