import GpsLogMap from '../components/GpsLogMap';
import GpsLogSummary from '../components/GpsLogSummary';
import GpsLogStats from '../components/GpsLogStats';
import '../components/styles.css';
import '../components/colors.css';
import { useEffect, useState } from 'react';
import { getGpsLogData } from '../core/GpsLogTracker';

function GpsLogExperiment() {

  const [data, setData] = useState([])

  // Check for data on load
  useEffect(() => {
    getGpsLogData().then(response => {
      // Reorder the response data sorting by the unixTime property descending
      response.sort((a, b) => (a.unixTime < b.unixTime) ? 1 : -1)
      setData(response)
    })
    // const interval = setInterval(() => {
    //   getGpsLogData().then(response => {
    //     // Reorder the response data sorting by the unixTime property descending
    //     response.sort((a, b) => (a.unixTime < b.unixTime) ? 1 : -1)
    //     setData(response)
    //   })
    // }, 180000);
    // return () => clearInterval(interval);
  }, []);

  return (
    <>
      <div className="LogTrackerWrapper">
        <div className="GpsLogSummaryContainer">
          <GpsLogSummary/>
        </div>
          <GpsLogMap
            data={data}
          />
        <div className="GpsLogStatsContainer">
          <GpsLogStats
            data={data}
          />
        </div>
      </div>      
    </>
  );
}

export default GpsLogExperiment;
