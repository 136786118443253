import Forest from './images/Bridge.jpg'
import River from './images/Pools.jpg'
import Estuary from './images/Estuary.jpg'
import Ocean from './images/Afloat.png'
import Hawaii from './images/Hawaii.jpg'
import Deepsea from './images/Deepsea.jpg'

function GpsLogRiverSection () 
{

    return (
        <>
            <div className="RiverRow" style={{paddingBottom: '2em'}}>
                <div className="RiverTitle">FOREST TO THE SEA</div>
            </div>
            <div className="RiverRow">
                <div className="RiverImage">
                    <div className="RiverImageTitle">Forest</div>
                    <img src={Forest} alt=""/>
                    <div className="RiverImageCaption">
                        Trevarrow, E., Arismendi, I. The role of large wood in streams as ecological corridors for wildlife biodiversity. <a href="https://doi.org/10.1007/s10531-022-02437-2">Biodivers Conserv 31, 2163–2178 (2022). (link)</a>
                    </div>
                </div>
                <div className="RiverText">
                    The most bountiful source of large wood in western Oregon has been old-growth forests, where trees live for hundreds, and last often thousands of years once they senesce or die and become large wood. Once a tree meets the forest floor where it will rest for hundreds or even thousands of years, it goes through both physical and chemical changes. Each fallen tree makes contact with the forest floor in its own unique way. This contact creates a gradient of conditions that change the physical and chemical composition, providing different opportunities for organisms. Here, large wood becomes an important corridor for the movement of critters in the old-growth forest. In the event of a large rain event, large wood near waterbodies can move downstream to larger rivers. 
                </div>
            </div>
                <div className="RiverRow">
                <div className="RiverImage">
                    <div className="RiverImageTitle">River</div>
                    <img src={River} alt=""/>
                    <div className="RiverImageCaption">
                        Sedell, James R.; Bisson, Peter A.; Swanson, Frederick J.; Gregory, Stanley V. 1988. What we know about large trees that fall into streams and rivers. In: Maser, Chris; Tarrant, Robert F.; Trappe, James M.; Franklin, Jerry F., tech. eds. From the forest to the sea: a story of fallen trees. Gen. Tech. Rep. PNW-GTR-229. Portland, OR: U.S. Department of Agriculture, Forest Service, Pacific Northwest Research Station; U.S. Department of the Interior, Bureau of Land Management: 47-81.
                    </div>
                </div>
                <div className="RiverText">
                    Large wood in the river ecosystem plays an important role in the geomorphological processes that shape our waterbodies. It gets snagged on the riverbed, on large rocks, on other large wood, and accumulates sediment, changing the shape and flow of the river. This large wood creates riffles and pools, important for the rearing of our beloved anadromous fish. Pools are where the stream deepens and water slows, fish like trout and cohos salmon feed in pools. The depth and tranquility of the water provide a habitat that allows different types and ages of fish to inhabit their preferred water layers. The wood protruding from the surface of the water is used by birds like the great blue heron and belted kingfisher to hunt fish. As large pieces of wood bound down the river, generally during floods, they knock large wood loose, sending it further downstream.
                </div>
            </div>
            <div className="RiverRow">
                <div className="RiverImage">
                    <div className="RiverImageTitle">Estuary</div>
                    <img src={Estuary} alt=""/>
                    <div className="RiverImageCaption">
                        Hood, Greg. (2007). Large woody debris influences vegetation zonation in an oligohaline tidal marsh. Estuaries and Coasts. 30. 441-450. 10.1007/BF02819390. 
                    </div>
                </div>
                <div className="RiverText">
                    In Western Oregon, our rivers eventually lead to the Pacific Ocean. Here, a cyclic dance between the rivers and oceans creates a fluid mixing and exchanging of sediment, nutrients, and debris. The estuary is a flat, muddy environment where large wood provides a welcomed structure. As ocean tides push and pull at the estuary, large wood slowly makes its way to the shores where currents push it up and down the west coast in the form of driftwood that floats again.
                </div>
            </div>
            <div className="RiverRow">
                <div className="RiverImage">
                    <div className="RiverImageTitle">Ocean</div>
                    <img src={Ocean} alt=""/>
                    <div className="RiverImageCaption">
                        Gonor, Jefferson J.; Sedell, James R.; Benner, Patricia, 1988. What we know about large trees in estuaries, in the sea, and on the coastal beaches. In: Maser, Chris; Tarrant, Robert F.; Trappe, James M.; Franklin, Jerry F., tech. eds. From the forest to the sea: a story of fallen trees. Gen. Tech. Rep. PNW-GTR-229. Portland, OR: U.S. Department of Agriculture, Forest Service, Pacific Northwest Research Station; U.S. Department of the Interior, Bureau of Land Management: 83-112..
                    </div>
                </div>
                <div className="RiverText">
                    The destiny of large woody debris in the marine ecosystem is awe-inspiring. Large wood is at the will of currents and tides, all of which change with the seasons. While in the clutches of the currents, plants, mollusks, krill, tuna, birds, and insects can inhabit large wood. Entire ecosystems float below the large wood. Even commercial fishers look for adrift wood, for its reputation for hosting commercial fish.
                </div>
            </div>
            <div className="RiverRow">
                <div className="RiverImage">
                    <div className="RiverImageTitle">Hawaii</div>
                    <img src={Hawaii} alt=""/>
                    <div className="RiverImageCaption">
                        Gonor, Jefferson J.; Sedell, James R.; Benner, Patricia, 1988. What we know about large trees in estuaries, in the sea, and on the coastal beaches. In: Maser, Chris; Tarrant, Robert F.; Trappe, James M.; Franklin, Jerry F., tech. eds. From the forest to the sea: a story of fallen trees. Gen. Tech. Rep. PNW-GTR-229. Portland, OR: U.S. Department of Agriculture, Forest Service, Pacific Northwest Research Station; U.S. Department of the Interior, Bureau of Land Management: 83-112..
                    </div>
                </div>
                <div className="RiverText">
                   This voyage across the Pacific ocean is a treacherous one. However, Large Woody Debris acts as a refuge and vector for all kinds of life during this journey. Large wood can float for such long distances that wood from the West Coast can be found washed ashore on the beaches of Hawaii.
                </div>
            </div>
            <div className="RiverRow">
                <div className="RiverImage">
                    <div className="RiverImageTitle">Deep Sea</div>
                    <img src={Deepsea} alt=""/>
                    <div className="RiverImageCaption">
                        Bienhold C, Pop Ristova P, Wenzhöfer F, Dittmar T, Boetius A (2013) How Deep-Sea Wood Falls Sustain Chemosynthetic Life. PLOS ONE 8(1): e53590. https://doi.org/10.1371/journal.pone.0053590
                    </div>
                </div>
                <div className="RiverText">
                    Yet, unlucky voyagers may find themselves sinking to the ocean where they either die from the extreme environmental conditions or are consumed by deep ocean mollusks, crabs and fish. Despite losing its travelers, large woody debris continues to support life. The bottom of the ocean is an unforgiving place where nutrients and carbon are hard to come by. Not only do these large pieces of wood provide nourishment to the organisms at the bottom of the ocean, it also provides structural complexity for habitats and entire ecosystems.
                </div>
            </div>
        </>
    )
}

export default GpsLogRiverSection
